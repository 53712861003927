import React, { useState, useEffect } from 'react';
import HeroSection from './hero';
import AboutSection from './aboutSection';
import CareerSection from './careerSection';
import AreasServed from './areasServed';
import Connect from './connect';
import Sidebar from './sidebar';
import Testimonials from './testimonials';
import VideoSection from './videoSection';
import CTABlock from './ctaBlock';

import { abbreviateState } from 'utils/utils.js';

const RETRY_INTERVAL = 2000;

export function AgentProfile() {
  const [agentData, setAgentData] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [starRating, setStarRating] = useState(null);

  useEffect(() => {
    const { state, agentId } = parseAgentProfileURL(window.location.pathname);
    fetchAgentData(agentId, state);
    fetchTestimonials(agentId)
  }, []);

  const parseAgentProfileURL = (pathname) => {
    const parts = pathname.split('/').filter(part => part !== '');
    if (parts.length < 3) {
      console.error("Invalid URL format");
      return { state: null, agentId: null };
    }
    // Convert dash to space and capitalize each word
    const stateFromUrl = parts[1].split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    
    const state = abbreviateState(stateFromUrl, 'full');
    const agentId = parts[2].substring(parts[2].lastIndexOf('-') + 1);
    return { state, agentId };
  };

  const fetchTestimonials = async (agentId) => {
    try {
      const response = await fetch(`https://api.myagentfinder.com/api/v0/agents/show/reviews/${agentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers that fetchAgentData might be using
        },
        body: JSON.stringify({
          api_key: '6f46b803be8ec56b3fe72e6e24d2f729f62dc83c',
          secret_key: '515492aa8f247e832b1736d51329cd785eae7f92',
        }),
      });

      if (!response.ok) {
        const errorText = await response.text(); // Let's see what the error response is
        console.error('Testimonials API Error:', errorText);
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && data.data) {
        setTestimonials(data.data);
        
        // Calculate average star rating
        const avgRating = data.data.reduce((acc, review) => acc + (review.starrating__c || 0), 0) / data.data.length;
        setStarRating(avgRating || 5);
      }
    } catch (error) {
      console.error('Failed to fetch testimonials:', error);
    }
  };

  const fetchAgentData = async (agentId, stateFromURL) => {
    try {
      const response = await fetch(`https://api.myagentfinder.com/api/v0/agents/get/${agentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: '6f46b803be8ec56b3fe72e6e24d2f729f62dc83c',
          secret_key: '515492aa8f247e832b1736d51329cd785eae7f92',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (abbreviateState(data.response.agent.state, 'full').toLowerCase() !== stateFromURL.toLowerCase()) {
        console.warn('State mismatch. Content will not be loaded.');
        return;
      }

      const mergedData = mergeAgentData(data.response.agent, agentId);
      setAgentData(mergedData);
      localStorage.setItem(agentId, JSON.stringify(mergedData));
    } catch (error) {
      console.error('Failed to fetch agent data:', error);
      setTimeout(() => fetchAgentData(agentId, stateFromURL), RETRY_INTERVAL);
    }
  };

  const mergeAgentData = (apiData, sfid) => {
    //const staticData = testAgents.find(agent => agent.sfid === sfid) || {};
    return { ...apiData, sfid };
  };

  const whitelist = ['0033a00002ev7BbAAI', '0033a00002qn2PKAAY', '003j0000015uFZRAA2', '0033a00002gCh4KAAS', '003Rl000001ynTXIAY', '0033a00002lhy9RAAQ', '003Rl0000026VRNIA2', '003j000001MZYIuAAP'];

  const isWhitelisted = (sfid) => whitelist.includes(sfid);
  
  const hasActiveExpertSubscription = (agent) => 
    agent.subscription_status === 'Active' && 
    agent.subscription_plan === 'Expert';

  const shouldShowProfile = (agent) => {
    if (!agent) return false;
    return isWhitelisted(agent.sfid) || hasActiveExpertSubscription(agent);
  };

  if (!shouldShowProfile(agentData)) {
    return <div></div>;
  }

  if (!agentData) {
    return <div></div>;
  }

  const socialLinks = {
    zillow: agentData.zillow,
    youtube: agentData.youtube,
    facebook: agentData.facebook_url,
    linkedin: agentData.linkedin_url,
    yelp: agentData.yelp,
    twitter: agentData.twitter_url,
    instagram: agentData.instagram_url,
    pinterest: agentData.pinterest_url,
  };

  const aboutSection = {
    firstname: agentData.firstname,
    description: agentData.description,
  };

  const contactDetails = {
    name: agentData.firstname,
    email: agentData.email,
    main_phone: agentData.main_phone,
    website: agentData.website,
    street: agentData.street,
    city: agentData.city,
    state: abbreviateState(agentData.state, 'full'),
    postal_code: agentData.postalcode,
  };

  const careerSection = {
    transactions: agentData.transactions,
    years_of_experience: agentData.experience_years,
    homes_sold: agentData.num_sold_opps,
  };

  const areasServed = {
    service_area: agentData.service_area,
  };

  return (
    <div className='flex flex-col items-center'>
      <HeroSection
        agentID={agentData.sfid}
        agentName={agentData.firstname}
        agentLastName={agentData.lastname}
        agentProfileImage={agentData.profile_image}
        agentCompanyName={agentData.company_name}
        starRating={starRating}
        testimonials={testimonials}
      />
      <CareerSection careerSection={careerSection} />
      <div className='wrapper flex flex-col items-center pt-[48px] pr-[24px] pb-0 pl-[24px] sm:pl-[48px] sm:pr-[48px] sm:py-[64px] justify-center gap-[4px] self-stretch font-poppins'>
        <div className='flex max-w-[500px] flex-col items-start gap-[40px] flex-[1_0_0] lg:flex-row md:max-w-[1140px]'>
          <div className='flex flex-col items-start gap-[40px]'>
            <div className='container flex w-full flex-col items-start gap-[40px]'>
              <div className='inline-flex flex-col items-start justify-start w-full gap-10 Container'>
                <div className='w-full lg:hidden'>
                  <Sidebar agentID={agentData.sfid} contactDetails={contactDetails} />
                </div>
                {aboutSection.description && <AboutSection aboutSection={aboutSection} />}
                {areasServed.service_area && <AreasServed areasServed={areasServed} />}
                {Object.values(socialLinks).some(link => link) && <Connect socialLinks={socialLinks} />}
                { testimonials && testimonials.length > 0 && <Testimonials testimonials={testimonials} />}
              </div>
            </div>
          </div>
          <div className='hidden lg:flex sidebar md:sticky top-0 w-full md:w-[353px] md:pt-[24px] flex-col items-start gap-[4px]'>
            <Sidebar agentID={agentData.sfid} contactDetails={contactDetails} />
          </div>
        </div>
        {agentData.video_url && <VideoSection agentName={agentData.firstname} videoUrl={agentData.video_url} />}
        <CTABlock agentID={agentData.sfid} agentName={agentData.firstname} agentProfileImage={agentData.profile_image} />
      </div>
    </div>
  );
}

export default AgentProfile;
