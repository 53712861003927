import React from 'react';

const AreasServed = ({ areasServed }) => {
  return (
    areasServed.service_area && (
      <div className='AreasServed self-stretch pt-6 pb-4 border-t border-[#d0d5dd] flex-col justify-start items-start gap-4 flex'>
        <div className='Text self-stretch text-[#2a2f64] text-[19px] sm:text-[25px] font-medium leading-normal'>
          Areas Served
        </div>
        <div className='flex flex-col items-start self-stretch justify-start gap-4 Frame901'>
          <div className='Text self-stretch text-[#2a2f64] text-[16px] font-normal leading-normal'>
            {areasServed.service_area}
          </div>
        </div>
      </div>
    )
  );  
};

export default AreasServed;
