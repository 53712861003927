import React from 'react';

const CareerSection = ({ careerSection }) => {
  return (
    <div className='self-stretch flex border-[#d0d5dd] flex-col justify-start items-start gap-4 px-[24px] sm:px-[48px] py-0'>
      <div className='flex flex-col items-start self-stretch justify-start gap-4 sm:flex-row max-w-[1140px] px-0 py-0 Frame901 mx-[auto] my-[0]'>
        {careerSection.transactions !== undefined && careerSection.transactions !== null && careerSection.transactions !== 0 && (
        <div className='inline-flex flex-col items-center self-stretch justify-start w-full gap-[20px] sm:gap-5 px-[16px] py-[24px] sm:px-6 sm:py-8 MetricItem grow shrink basis-0 bg-gray-50 rounded-2xl'>
          <div className='flex flex-col items-center self-stretch justify-start gap-3 NumberAndText'>
            <div className='Number self-stretch text-center text-[#6471ed] text-[27px] sm:text-[39px] font-normal leading-[100%] sm:leading-[52.65px] font-new-spirit'>{careerSection.transactions}</div>
            <div className='Text self-stretch text-center text-[#2a2f64] text-[16px] font-semibold leading-tight'>Transactions Closed</div>
          </div>
        </div>
        )}
        {careerSection.homes_sold !== undefined && careerSection.homes_sold !== null && careerSection.homes_sold !== 0 && (
        <div className='inline-flex flex-col items-center justify-start w-full gap-[20px] sm:gap-5 px-[16px] py-[24px] sm:px-6 sm:py-8 MetricItem grow shrink basis-0 bg-gray-50 rounded-2xl'>
          <div className='flex flex-col items-center self-stretch justify-start gap-3 NumberAndText'>
            <div className='Number self-stretch text-center text-[#6471ed] text-[27px] sm:text-[39px] font-normal leading-[100%] sm:leading-[52.65px] font-new-spirit'>{careerSection.homes_sold}</div>
            <div className='Text self-stretch text-center text-[#2a2f64] text-[16px] font-semibold leading-tight'>Homes Sold with Home & Money</div>
          </div>
        </div>
        )}
        {careerSection.years_of_experience !== undefined && careerSection.years_of_experience !== null && careerSection.years_of_experience !== 0 && (
        <div className='inline-flex flex-col items-center self-stretch justify-start w-full gap-[20px] sm:gap-5 px-[16px] py-[24px] sm:px-6 sm:py-8 MetricItem grow shrink basis-0 bg-gray-50 rounded-2xl'>
          <div className='flex flex-col items-center self-stretch justify-start gap-3 NumberAndText'>
            <div className='Number self-stretch text-center text-[#6471ed] text-[27px] sm:text-[39px] font-normal leading-[100%] sm:leading-[52.65px] font-new-spirit'>{careerSection.years_of_experience}</div>
            <div className='Text self-stretch text-center text-[#2a2f64] text-[16px] font-semibold leading-tight'>Years of Experience</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CareerSection;