import React from 'react';

const ctaBlock = ({ agentID, agentName, agentProfileImage }) => {
  return (
    <div className="flex max-w-[1140px] sm:px-[48px] py-[64px] gap-[32px] pb-[48px] flex-col justify-center items-center self-stretch mx-[auto] my-[0] Frame895">
      <div className="CtaBox pl-6 pr-6 bg-[url(https://cdn.homeandmoney.com/wp-content/uploads/2023/10/12123118/Pattern-bottom-bar-lotus.webp)] bg-auto bg-bottom bg-no-repeat w-full h-full self-stretch  px-12 pt-8 bg-[#434ca0] rounded-[48px] border-8 border-[#838df1] flex-col justify-center items-center gap-16 flex">
        <div className="flex flex-col items-center self-stretch justify-center gap-8 HeadlineAndCta">
          {agentProfileImage && (
            <div className="flex flex-col items-center self-stretch justify-start gap-2 Frame427319839">
              <img className="Img w-16 h-16 relative rounded-[100px] border-2 border-[#cfd3f9] object-cover object-center" src={agentProfileImage} />
              <div className="flex flex-col items-center self-stretch justify-start gap-4 HeadlineText">
                <div className="Headline text-[#FFF] text-center text-[27px] sm:text-[39px] not-italic font-normal leading-[135%] font-new-spirit">Get Started with {agentName}</div>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center justify-center gap-4 sm:flex-row Ctas">
              <a href={`/selling?agentID=${agentID}`} className="Button w-full sm:w-auto px-11 py-6 bg-gradient-to-bl from-[#f9e0d6] to-[#f3bda7] hover:from-[#f3bda7] hover:to-[#f3bda7] rounded-lg shadow flex-col justify-center items-center inline-flex">
                  <span className="Label text-center text-[#5b3c2f] text-[16px] font-semibold leading-none">I'm Selling</span>
              </a>
              <a href={`/buying?agentID=${agentID}`} className="Button w-full sm:w-auto px-11 py-6 bg-white hover:bg-[#F2F4F7] rounded-lg shadow border border-[#97a0f3] flex-col justify-center items-center inline-flex">
                <span className="Label text-center text-[#434ca0] text-[16px] font-semibold leading-none">I'm Buying</span>
              </a>
          </div>
          <div className="flex flex-col items-center self-stretch justify-start gap-2 Frame427319839">
            <img src='https://cdn.homeandmoney.com/wp-content/uploads/2023/10/12123117/CTA-Box-House_Desktop.webp' />
          </div>
        </div>
      </div>
  </div>
  );
};

export default ctaBlock;
